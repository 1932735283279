import { createIcon } from "@chakra-ui/react";

const BrokenLinkIcon = createIcon({
  displayName: "BrokenLinkIcon",
  viewBox: "0 0 89 89",
  path: [
    <path
      key="0"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.684 20.2933C68.0982 20.6913 68.1062 21.3443 67.702 21.752L63.7979 25.689C63.3936 26.0967 62.7302 26.1046 62.316 25.7067C61.9018 25.3087 61.8938 24.6557 62.298 24.248L66.2021 20.311C66.6064 19.9033 67.2698 19.8954 67.684 20.2933Z"
      fill="#003C69"
    />,
    <path
      key="1"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.9569 10.0001C52.4919 9.99187 52.9308 10.5347 52.9373 11.2124L52.9999 17.7578C53.0064 18.4356 52.578 18.9917 52.0431 18.9999C51.5081 19.0081 51.0692 18.4653 51.0627 17.7876L51.0001 11.2422C50.9936 10.5644 51.422 10.0083 51.9569 10.0001Z"
      fill="#003C69"
    />,
    <path
      key="2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.9999 35.9571C78.0081 36.4922 77.4653 36.9311 76.7875 36.9376L70.2421 36.9999C69.5643 37.0064 69.0083 36.5779 69.0001 36.0429C68.9919 35.5078 69.5347 35.0689 70.2125 35.0624L76.7579 35.0001C77.4357 34.9936 77.9917 35.4221 77.9999 35.9571Z"
      fill="#003C69"
    />,
    <path
      key="3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.684 62.2933C27.0982 62.6913 27.1062 63.3443 26.702 63.752L22.7979 67.689C22.3936 68.0967 21.7302 68.1046 21.316 67.7067C20.9018 67.3087 20.8938 66.6557 21.298 66.248L25.2021 62.311C25.6064 61.9033 26.2698 61.8954 26.684 62.2933Z"
      fill="#003C69"
    />,
    <path
      key="4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9999 52.4354C20.0081 53.2378 19.4653 53.8962 18.7876 53.9059L12.2422 53.9999C11.5644 54.0096 11.0083 53.367 11.0001 52.5646C10.9919 51.7622 11.5347 51.1038 12.2124 51.0941L18.7578 51.0001C19.4356 50.9904 19.9917 51.633 19.9999 52.4354Z"
      fill="#003C69"
    />,
    <path
      key="5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9569 69.0001C37.4919 68.9919 37.9308 69.5347 37.9373 70.2124L37.9999 76.7578C38.0064 77.4356 37.578 77.9917 37.0431 77.9999C36.5081 78.0081 36.0692 77.4653 36.0627 76.7876L36.0001 70.2422C35.9936 69.5644 36.422 69.0083 36.9569 69.0001Z"
      fill="#003C69"
    />,
    <path
      key="6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.9753 7.37842C25.8185 0.382005 14.3531 0.521133 7.36729 7.6892L7.36727 7.68922C0.381462 14.8569 0.520375 26.3396 7.67721 33.3361C7.67722 33.3361 7.67722 33.3361 7.67723 33.3361L14.0244 39.5408C14.9746 40.4694 15.9995 41.2717 17.0785 41.9483C17.5975 42.2738 17.7548 42.959 17.4298 43.4787C17.1049 43.9985 16.4207 44.156 15.9018 43.8306C14.6895 43.0704 13.5398 42.1701 12.4758 41.1303L12.4757 41.1302L6.12837 34.9253L6.12835 34.9253C-1.90481 27.0722 -2.0608 14.1834 5.78048 6.13799L6.57387 6.9136L5.78048 6.13799C13.6217 -1.90775 26.4909 -2.06397 34.5242 5.7892C34.5242 5.78921 34.5242 5.78921 34.5242 5.78922L40.8715 11.9941C48.9051 19.8472 49.0606 32.7356 41.2194 40.7814C40.2064 41.8209 39.1083 42.7295 37.9469 43.5061C37.4376 43.8466 36.7492 43.7092 36.4092 43.1992C36.0691 42.6892 36.2063 41.9997 36.7156 41.6592C37.7494 40.9679 38.7282 40.1583 39.6325 39.2302L39.6326 39.2302C46.6185 32.0621 46.4798 20.5797 39.3227 13.5833L32.9753 7.37843L32.9753 7.37842Z"
      fill="#003C69"
    />,
    <path
      key="7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7518 13.2155C23.1384 9.38152 17.3256 9.4586 13.7987 13.3859L13.7987 13.386C10.2712 17.3136 10.3415 23.6311 13.9554 27.4649L18.653 32.4485C19.0874 32.9094 19.0959 33.6658 18.6718 34.138C18.2478 34.6102 17.5519 34.6194 17.1175 34.1585L12.4199 29.1749C12.4199 29.1749 12.4199 29.1749 12.4199 29.1749C7.93715 24.4193 7.84992 16.5888 12.2256 11.7168C16.6004 6.84534 23.8049 6.74961 28.2874 11.5057M28.2875 11.5057L34.5802 18.1818C34.5802 18.1818 34.5802 18.1818 34.5802 18.1818C39.063 22.9374 39.1498 30.7683 34.7745 35.6398C34.3505 36.112 33.6546 36.1211 33.2202 35.6602C32.7858 35.1993 32.7774 34.4429 33.2014 33.9707C36.7286 30.0435 36.6586 23.7255 33.0447 19.8918L26.7518 13.2155"
      fill="#003C69"
    />,
    <path
      key="8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.7203 44.5152C72.0492 43.9968 72.7346 43.8442 73.2511 44.1743C74.4139 44.9174 75.508 45.7924 76.5231 46.7865L76.5232 46.7866L82.8709 53.0048C90.9053 60.8752 91.0609 73.791 83.2188 81.854C79.2975 85.886 74.1121 87.9354 68.9181 87.9985C63.724 88.0616 58.4909 86.139 54.4734 82.2031L48.1258 75.985C45.2406 73.1588 43.3724 69.6842 42.5312 66.0084C41.7858 62.8016 41.8224 59.4617 42.6568 56.2657C43.5028 52.9229 45.214 49.7716 47.7778 47.1354C48.7921 46.0924 49.8904 45.181 51.0533 44.4026C51.5627 44.0617 52.2511 44.1997 52.5909 44.711C52.9306 45.2223 52.7931 45.9132 52.2836 46.2542C51.2497 46.9462 50.2709 47.7581 49.3648 48.6899L49.3647 48.6899C47.0762 51.0431 45.5561 53.8472 44.8051 56.8174C44.8044 56.8203 44.8036 56.8231 44.8029 56.826C44.0596 59.6704 44.0262 62.6451 44.6911 65.5046L44.692 65.5085C45.4403 68.7795 47.1015 71.8717 49.6747 74.3923L49.6747 74.3923L56.0224 80.6105L56.0224 80.6106C59.6008 84.1162 64.2593 85.8294 68.8912 85.7731C73.5231 85.7168 78.1392 83.8908 81.6319 80.2995L82.4253 81.0767L81.6319 80.2994C88.6185 73.116 88.4799 61.6093 81.3219 54.5974L82.0964 53.8011L81.3219 54.5974L74.9743 48.3793C74.9743 48.3793 74.9743 48.3793 74.9742 48.3792C74.0638 47.4876 73.0889 46.7091 72.0601 46.0516C71.5435 45.7215 71.3914 45.0336 71.7203 44.5152Z"
      fill="#003C69"
    />,
    <path
      key="9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.02 24.5067C32.3745 25.0303 32.232 25.7386 31.7019 26.0887C30.6289 26.7972 29.6127 27.6269 28.6732 28.5775C21.3974 35.9397 21.5418 47.7331 28.996 54.9191L28.1894 55.7352L28.996 54.9191L30.0531 55.9381L30.5826 54.8595C30.5826 54.8594 30.5826 54.8594 30.5827 54.8594C31.273 53.4529 32.407 52.306 33.8132 51.5901L33.8133 51.5901L34.8923 51.0409L33.8351 50.0218L33.8351 50.0218C29.1262 45.4822 29.0347 38.0078 33.6309 33.3568C34.0764 32.906 34.8074 32.8972 35.2638 33.3372C35.7202 33.7771 35.7291 34.4992 35.2837 34.9499C31.5782 38.6996 31.6522 44.7299 35.4482 48.3895C35.4482 48.3895 35.4482 48.3895 35.4482 48.3895L37.6518 50.5138C37.9174 50.7699 38.0422 51.1364 37.9872 51.499C37.9322 51.8615 37.7041 52.1758 37.3742 52.3437L34.8713 53.6176C33.9087 54.1076 33.1329 54.8925 32.6608 55.8544L32.6607 55.8545L31.4321 58.3571C31.2701 58.687 30.9575 58.9198 30.5918 58.983C30.2262 59.0461 29.8521 58.9317 29.5865 58.6757L27.3829 56.5513C19.0158 48.4854 18.8538 35.2479 27.0205 26.9842L27.0206 26.9841C28.0726 25.9198 29.2126 24.9887 30.4183 24.1925C30.9485 23.8424 31.6656 23.9831 32.02 24.5067Z"
      fill="#003C69"
    />,
    <path
      key="10"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.9696 30.0169C59.3304 29.9543 59.6995 30.0677 59.9616 30.3215L62.136 32.4275C70.392 40.4239 70.5519 53.5474 62.4935 61.7398C61.4524 62.7984 60.3239 63.7234 59.1303 64.5142C58.607 64.861 57.8995 64.7211 57.55 64.2018C57.2006 63.6824 57.3416 62.9804 57.8649 62.6336C58.9274 61.9297 59.9333 61.1054 60.8627 60.1604L60.8627 60.1604C68.0421 52.8616 67.8996 41.1698 60.5442 34.0458L59.5012 33.0355L58.9787 34.1048C58.9787 34.1049 58.9786 34.1049 58.9786 34.1049C58.2975 35.4993 57.1785 36.6363 55.7909 37.346L54.7261 37.8905L55.7693 38.9008C60.0055 43.0038 60.4532 49.5225 57.0665 54.1359C57.0503 54.1579 57.0334 54.1793 57.0157 54.2001L56.0255 55.3624C56.008 55.383 55.9897 55.4029 55.9707 55.4222C53.2278 58.2105 52.5903 62.261 54.014 65.6588C54.5001 66.7902 55.2046 67.8466 56.1331 68.7459L56.1332 68.7459L62.6563 75.0639L62.6563 75.0639C66.6972 78.9781 73.3862 78.5865 76.9017 73.9561L76.9018 73.9561C79.7767 70.1698 79.1533 64.7094 75.6562 61.3224L71.083 56.8929C70.6327 56.4568 70.6239 55.7409 71.0635 55.2941C71.503 54.8472 72.2244 54.8386 72.6747 55.2747L77.248 59.7042C81.486 63.8088 82.3514 70.5366 78.7218 75.3168C74.3686 81.0506 66.0751 81.5356 61.0645 76.6821L54.5415 70.3641C54.5414 70.3641 54.5414 70.3641 54.5414 70.3641C53.3837 69.2429 52.5126 67.9318 51.9156 66.5405C51.9144 66.5377 51.9132 66.5349 51.912 66.5321C50.1564 62.3478 50.9245 57.3389 54.3109 53.8724L55.248 52.7724C57.9529 49.0548 57.5808 43.8152 54.1776 40.519L52.0032 38.413C51.7411 38.1592 51.6179 37.7958 51.6722 37.4364C51.7265 37.077 51.9516 36.7654 52.2771 36.5989L54.7469 35.336C54.7469 35.336 54.747 35.336 54.747 35.336C55.6967 34.8502 56.4622 34.072 56.9281 33.1185L56.9281 33.1184L58.1405 30.6374C58.3003 30.3103 58.6088 30.0795 58.9696 30.0169Z"
      fill="#003C69"
    />,
  ],
});

export default BrokenLinkIcon;
