import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react";
import BrokenLinkIcon from "components/BrokenLinkIcon";
import ChevronRightIcon from "components/ChevronRightIcon";
import Link from "next/link";
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";

const Custom404 = ({ isLoggedIn404Page }: { isLoggedIn404Page?: boolean }) => {
  return (
    <Center mt="12" minWidth="320px">
      <Box p="12" background="white" minWidth="320px" borderRadius="6">
        <Center flexDirection="column">
          <Stack gap="4" alignItems="center">
            <BrokenLinkIcon fontSize="96" />

            <Heading as="h1" fontSize="24" color="primary.dark">
              <FormattedMessage
                id="pageNotFound.title"
                defaultMessage="Page not found"
              />
            </Heading>

            <Text>
              <FormattedMessage
                id="pageNotFound.description"
                defaultMessage="Page you tried to access was not found. "
              />
            </Text>

            {isLoggedIn404Page ? (
              <Fragment>
                {/* // Should show a message of some kind? */}
              </Fragment>
            ) : (
              <Link href="/">
                <FormattedMessage
                  id="pageNotFound.goBackToLogin"
                  defaultMessage="Go to login"
                />
                <ChevronRightIcon />
              </Link>
            )}
          </Stack>
        </Center>
      </Box>
    </Center>
  );
};

Custom404.defaultProps = {
  is404Page: true,
};

export default Custom404;
