import { createIcon } from "@chakra-ui/react";

const ChevronRightIcon = createIcon({
  displayName: "ChevronRightIcon",
  viewBox: "5 5 10 10",
  path: (
    <path
      d="M12.704 9.31164L9.18636 6.2008C8.91486 5.93307 8.47479 5.93307 8.20362 6.2008C7.93213 6.46535 7.93213 6.89245 8.20362 7.157L11.2276 9.83436L8.20362 12.5117C7.93213 12.7763 7.93213 13.2034 8.20362 13.4679C8.47479 13.7357 8.91486 13.7357 9.18636 13.4679L12.704 10.3571C12.851 10.2137 12.9157 10.0224 12.9036 9.83436C12.9157 9.64631 12.851 9.45507 12.704 9.31164Z"
      fill="currentColor"
    />
  ),
});

ChevronRightIcon.defaultProps = {
  color: "currentColor",
};

export default ChevronRightIcon;
